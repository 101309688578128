import React from 'react'

const nationalIcon = () => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" stroke='#C91200' strokeWidth="1">
      <path d="M25.9699 15.9919C26.1119 15.8741 26.1971 15.7029 26.2049 15.518L26.3136 13.0112L32.5217 10.0266C32.7066 9.93741 32.8388 9.76751 32.8785 9.56572L33.396 6.9437C33.4656 6.59153 33.2358 6.24913 32.8837 6.17883C32.5302 6.11113 32.1885 6.33897 32.1188 6.69178L31.6638 8.99419L25.3984 12.0068C25.1816 12.1109 25.0404 12.3251 25.03 12.5653L24.9349 14.7421L19.8445 14.0032L18.3649 12.1825C18.1384 11.9033 17.7283 11.8616 17.4497 12.0875L15.4311 13.7266C15.2312 13.8887 15.146 14.1536 15.213 14.4023L16.6627 19.7505L11.8151 20.4724L9.90452 18.2259C9.73202 18.0222 9.45211 17.9467 9.19954 18.0307L1.3797 20.6794C1.19873 20.7412 1.05357 20.8786 0.983268 21.0563C0.912966 21.2333 0.924032 21.4338 1.01386 21.6024L4.04078 27.278C4.10196 27.3926 4.1957 27.4857 4.31027 27.5462L13.8525 32.6113L18.581 41.1146L17.231 56.4581L15.4929 58.3127C15.3549 58.4605 15.2924 58.6636 15.3256 58.8634C15.3588 59.0632 15.4825 59.2357 15.6609 59.3321L22.7243 63.116C22.8187 63.1662 22.9248 63.1929 23.0316 63.1929H32.4918C32.8511 63.1929 33.1427 62.9019 33.1427 62.5419C33.1427 62.1819 32.8511 61.891 32.4918 61.891H23.1949L17.0233 58.5841L18.3356 57.1846C18.4352 57.0785 18.4964 56.9418 18.5094 56.7966L19.8966 41.03C19.9083 40.9005 19.8803 40.7703 19.8172 40.6563L14.898 31.8106C14.8368 31.7012 14.7456 31.6108 14.6343 31.5528L5.09792 26.4904L2.52537 21.6675L9.2002 19.4061L11.0587 21.5914C11.2045 21.7632 11.4271 21.8459 11.6504 21.814L17.5792 20.9306C17.7628 20.9033 17.9262 20.7985 18.0277 20.6429C18.1293 20.488 18.1599 20.296 18.111 20.1163L16.5807 14.4706L17.7654 13.5085L18.995 15.0213C19.0979 15.1476 19.245 15.2309 19.4064 15.2544L25.4609 16.1331C25.6419 16.1624 25.828 16.1084 25.9699 15.9919Z" fill="#C91200" />
      <path d="M65.5188 17.4089L55.256 13.4837L50.3361 12.008L43.8787 6.66763C43.8546 6.6481 43.8292 6.62922 43.8025 6.6136L38.8722 3.60622L38.4244 1.48477C38.384 1.29665 38.2629 1.13521 38.093 1.04473C37.9231 0.95425 37.722 0.943834 37.5423 1.01609L33.1907 2.78146C32.8574 2.91686 32.6973 3.29636 32.8321 3.62965C32.9668 3.96294 33.3463 4.12307 33.6802 3.98832L37.3106 2.51523L37.6556 4.15041C37.6927 4.32616 37.8008 4.47784 37.9537 4.57092L43.0845 7.70069L49.6077 13.0951C49.6754 13.1504 49.7528 13.1921 49.8362 13.2175L54.8367 14.7153L64.4936 18.4107L61.8383 27.5273L61.0845 27.9042C60.983 27.955 60.8958 28.0318 60.8326 28.1268L54.1474 38.2178C53.9886 38.458 54.0061 38.7744 54.191 38.9957C54.3765 39.2164 54.6857 39.2899 54.9487 39.1747L58.0602 37.826L59.9903 44.2573L57.8311 45.8769C57.5857 46.0611 57.5024 46.3924 57.6313 46.671L60.7845 53.4819C60.8945 53.7195 61.1295 53.8595 61.3755 53.8595C61.4673 53.8595 61.5597 53.8406 61.6483 53.7989C61.9744 53.6486 62.117 53.2613 61.9653 52.9351L59.0334 46.6021L61.1353 45.0254C61.3534 44.8614 61.4465 44.5789 61.3677 44.3179L59.0972 36.7499C59.0438 36.5722 58.9169 36.4251 58.7483 36.3463C58.579 36.2689 58.3851 36.2663 58.2152 36.3392L56.4446 37.1067L61.8221 28.9906L62.6748 28.5643C62.8369 28.4829 62.958 28.3377 63.0088 28.1639L65.9113 18.1985C66.0057 17.8737 65.8352 17.53 65.5188 17.4089Z" fill="#C91200" />
      <path d="M61.0588 56.0472L55.2472 59.3163L45.1711 56.2438C44.9817 56.1859 44.776 56.2171 44.6132 56.3291L40.1979 59.3566C40.0208 59.4784 39.9147 59.6789 39.9147 59.8937V64.4139H34.3855C34.0262 64.4139 33.7346 64.7048 33.7346 65.0648C33.7346 65.4248 34.0262 65.7158 34.3855 65.7158H40.5656C40.9256 65.7158 41.2166 65.4248 41.2166 65.0648V60.2361L45.091 57.5796L55.1339 60.6416C55.3057 60.6943 55.488 60.6748 55.6429 60.5869L61.6974 57.1812C62.0105 57.0048 62.1218 56.6083 61.9454 56.2952C61.769 55.9821 61.3719 55.8708 61.0588 56.0472Z" fill="#C91200" />
      <path d="M44 52.5C44 53.3284 43.3284 54 42.5 54C41.6716 54 41 53.3284 41 52.5C41 51.6716 41.6716 51 42.5 51C43.3284 51 44 51.6716 44 52.5Z" fill="#C91200" />
      <path d="M33 52.5C33 53.3284 32.3284 54 31.5 54C30.6716 54 30 53.3284 30 52.5C30 51.6716 30.6716 51 31.5 51C32.3284 51 33 51.6716 33 52.5Z" fill="#C91200" />
      <path d="M25 42.5C25 43.3284 24.3284 44 23.5 44C22.6716 44 22 43.3284 22 42.5C22 41.6716 22.6716 41 23.5 41C24.3284 41 25 41.6716 25 42.5Z" fill="#C91200" />
      <path d="M39 23.5C39 24.3284 38.3284 25 37.5 25C36.6716 25 36 24.3284 36 23.5C36 22.6716 36.6716 22 37.5 22C38.3284 22 39 22.6716 39 23.5Z" fill="#C91200" />
    </svg>
  )
}

export default nationalIcon


