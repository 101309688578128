import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import Heading from 'components/global/Heading'

import background from './assets/background.svg'
import check from './assets/check.svg'

const StyledContainer = styled.div`
  margin-top: 200px;
  & > h2 {
    margin: 0;
  }
`
const StyledToolsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
`
const StyledToolContainer = styled.div`
  position: relative;
  padding: 30px 0;
  ${breakpoint('lg')`
    padding: 60px;
    flex: 0 0 50%;
    padding: 100px 80px 100px 120px;
  `}
  ${breakpoint('xxl')`
    padding: 80px 120px 100px 120px;
  `}
  h3 {
    margin-bottom: 10px;
    font-size: 16px;
    padding-left: 20px;
  }
  li {
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    img {
      margin-right: 7px;
      position: relative;
      top: 7px;
    }
  }
`
const StyledBGContainer = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  ${breakpoint('lg')`
    display: flex;
  `}
`

const OurToolsSection = () => {

  const tools = [
    {
      title: "Conaissance de l’écosystème",
      points: [
        "Benchmark des acteurs et solutions du marché concerné",
        "LMI Membre des associations Digital 113, SBA, Credo, Infranum, OpenSig",
        "Veille technologique"
      ]
    },
    {
      title: "Conaissance de l’écosystème1",
      points: [
        "Benchmark des acteurs et solutions du marché concerné",
        "LMI Membre des associations Digital 113, SBA, Credo, Infranum, OpenSig",
        "Veille technologique1"
      ]
    },
    {
      title: "Conaissance de l’écosystème2",
      points: [
        "Benchmark des acteurs et solutions du marché concerné",
        "LMI Membre des associations Digital 113, SBA, Credo, Infranum, OpenSig",
        "Veille technologique2"
      ]
    },
    {
      title: "Conaissance de l’écosystème3",
      points: [
        "Benchmark des acteurs et solutions du marché concerné",
        "LMI Membre des associations Digital 113, SBA, Credo, Infranum, OpenSig",
        "Veille technologique3"
      ]
    },
  ]

  const toolsRender = tools.map(tool => {
    return (
      <StyledToolContainer key={tool.title}>
        <h3>{tool.title}</h3>
        <ul>
          {tool.points.map(point => {
            return (
              <li key={point}>
                <img src={check} alt="point" />
                {point}
              </li>
            )
          })}
        </ul>
      </StyledToolContainer>
    )
  })

  return (
    <StyledContainer>
      <Heading section>Nos outils</Heading>
      <StyledToolsContainer>
        <StyledBGContainer>
          <img src={background} alt="fond" />
        </StyledBGContainer>
        {toolsRender}
      </StyledToolsContainer>
    </StyledContainer>
  )
}

export default OurToolsSection
