import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import Seo from 'components/global/Seo'
import Layout from '../components/global/Layout'
import Container from 'components/global/Container'
import PageHero from 'components/global/PageHero'
import FourPointsSection from 'components/pages/about/FourPointsSection'
import ProjectStepsSection from 'components/pages/about/ProjectStepsSection'
import OurToolsSection from 'components/pages/about/OurToolsSection'
import OurPartnersSection from 'components/pages/about/OurPartnersSection'

const OurMissionSection = styled.div`
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoint('md')`
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
  `}
  ${breakpoint('xl')`
    padding: 0 100px;
  `}
  & > * {
    flex: 0 0 47%;
    &:first-child {
      margin-bottom: 40px;
      ${breakpoint('md')`
        margin-bottom: 0;
      `}
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 900;
  }
  img {
    border-radius: 20px;
  }
`

const About = () => {

  return (
    <>
      <Seo pageTitle="Qui sommes-nous ?" />
      <Layout>
        <Container>
          <PageHero
            pageTitle="Qui sommes-nous&nbsp;?"
            pageDecription="LM Ingénierie est un bureau d’études techniques et architecte en aménagement des Territoires et Bâtiments connectés. La TEAM LMI est composée de femmes et d’hommes experts dans les nouvelles technologies liées au Digital, les infrastructures IT filaires ou radio, les systèmes de sûreté /sécurité des bâtiments et des territoires. Directeurs de projets, chef de projets, experts techniques sont à votre écoute et peuvent vous accompagner, au travers de missions de conseil en AMO, au suivi en maîtrise d’œuvre MOE durant le déploiement et la réception de votre projet."
            imgName="about" />
          <FourPointsSection />
          <OurMissionSection>
            <StaticImage
              src="../assets/imgs/notre-mission.jpg"
              height={300}
              placeholder="blurred"
              alt="Notre Mission"
            />
            <div>
              <h2>Notre Mission : Respect de la satisfaction client</h2>
              <p>Afin de répondre à l’ensemble des besoins de nos clients, nous mettons en place des méthodes et processus où l’humain et l’innovation sont positionnés au coeur des projets qui nous sont confiés. Tout au long de vos projets, nous vous accompagnons et facilitons les changements opérés au sein de votre organisation.</p>
            </div>
          </OurMissionSection>
          <ProjectStepsSection />
          <OurToolsSection />
        </Container>
        <OurPartnersSection />
      </Layout>
    </>
  )
}


export default About

