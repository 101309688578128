import React, { useState, useEffect, useRef } from 'react';
import ProjectStepsContainer from './ProjectStepsContainer'

import launch from './icons/launch.svg'
import user from './icons/user.svg'
import strategy from './icons/strategy.svg'
import communication from './icons/communication.svg'
import bilan from './icons/bilan.svg'

const projectSteps = [
  {
    icon: launch,
    title: "Lancement de mission",
    points: [
      "Forces et faiblesses",
      "Sensibilisation au contexte",
      "Expression des besoins"
    ]
  },
  {
    icon: user,
    title: "Mettre l’usager au centre des réflexions",
    points: [
      "Etat de l’art",
      "Benchmarks",
    ]
  },
  {
    icon: strategy,
    title: "Elaborer la stratégie",
    points: [
      "Etablir un plan d’action",
      "Définir les udages et services",
      "Mise en place d’une gouvernance",
    ]
  },
  {
    icon: communication,
    title: "Communiquer sur la vision et les projets",
    points: [
      "Evaluer l’impact des projets",
      "Evaluer le ROI",
    ]
  },
  {
    icon: bilan,
    title: "Bilan  / REX",
    points: [
      "Questionnaire de satisfaction",
      "Document de synthèse",
    ]
  },
]

const ProjectStepsSection = () => {

  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleIsSectionVisible = () => {
      const top = sectionRef.current.getBoundingClientRect().top;
      if ((top + 800) >= 0 && (top + 400) <= window.innerHeight) {
        setIsSectionVisible(true)
      } else {
        setIsSectionVisible(false)
      }
    }
    window.addEventListener('scroll', handleIsSectionVisible)
    return () => {
      window.removeEventListener('scroll', handleIsSectionVisible)
    };
  }, []);

  return (
    <div ref={sectionRef}>
      <ProjectStepsContainer
        projectSteps={projectSteps}
        isSectionVisible={isSectionVisible}
      />
    </div>
  )
}

export default ProjectStepsSection
