import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import EquipeIcon from './icons/equipeIcon'
import HumainIcon from './icons/humainIcon'
import NationalIcon from './icons/nationalIcon'
import RelationIcon from './icons/relationIcon'

const FourPointsSection = () => {

  const points = [
    {
      icon: <NationalIcon />,
      title: "Une représentativité nationale",
      description: "Créé en Occitanie en 2006, nous avons depuis ces dernières années renforcé notre représentativité nationale autour de 4 sites : Montpellier / Toulouse / Bordeaux / Paris."
    },
    {
      icon: <RelationIcon />,
      title: "Une relation de proximité",
      description: "Nous privilégions la relation de proximité que nous entretenons avec nos clients, au travers d’un véritable partenariat où la confiance et l’écoute nous permettent de répondre à l’ensemble de vos attentes."
    },
    {
      icon: <HumainIcon />,
      title: "L’humain et l’innovation au cœur",
      description: "Nous positionnons toujours l’humain et l’innovation au cœur de nos réflexions et des projets qui nous sont confiés."
    },
    {
      icon: <EquipeIcon />,
      title: "Une équipe pluridisciplinaire",
      description: "Fort d’une équipe pluridisciplinaire de plus de 14 personnes, composée de consultants, ingénieurs, chef de projets et experts techniques, nous adressons en mode agile l’ensemble des missions et projets complexes que nous pilotons."
    },
  ]

  const pointsRender = points.map(point => {
    return (
      <div key={point.title}>
        {point.icon}
        <h2>{point.title}</h2>
        <p>{point.description}</p>
      </div>
    )
  })

  return (
    <StyledContainer>
      {pointsRender}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${breakpoint('md')`
    flex-direction: row;
  `}
  div {
    display: flex;
    flex-direction: column;
    padding: 0 0 100px 0;
    ${breakpoint('md')`
      flex: 0 0 50%;
      &:not(:last-child, :nth-child(2)) {
        padding: 0 80px 0 0;
      }
    `}
    ${breakpoint('xl')`
      flex: 1;
      &:not(:last-child) {
        padding: 0 80px 0 0;
      }
    `}
    svg {
      align-self: center;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
      margin-top: 40px;
    }
  }
`

export default FourPointsSection
