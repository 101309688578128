import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PartnerCard from './PartnerCard';

const sliderSettings = {
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  focusOnSelect: true,
  arrows: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: theme.breakpoints.xl,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        slidesToShow: 1,
      }
    },
    {
      breakpoint: theme.breakpoints.sm,
      settings: {
        slidesToShow: 1,
        centerPadding: '20px'
      }
    },
  ]
};

const StyledSlider = styled(Slider)`
`

const PartnersSlider = ({ partnersData }) => {

  return (
    <StyledSlider {...sliderSettings}>
      {partnersData.map(partner => {
        return <PartnerCard key={partner.id} partner={partner} />
      })}
    </StyledSlider>
  )
}

export default PartnersSlider
