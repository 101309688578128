import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import check from './icons/check.svg'

const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.brand};
  color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.baseBorderRadius};
  padding: 40px;
  height: 406px;
  ${breakpoint('lg')`
    padding: 80px;
  `}
`
const StyleContent = styled.div`
  transition: all ${props => props.transitionDuration / 2}ms cubic-bezier(.25, .8, .25, 1);
  opacity: ${props => props.isTransition ? '0' : '1'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 15px;
  }
  li {
    font-size: 16px;
    margin-bottom: 10px;
    img {
      margin-right: 6px;
    }
  }
`

const StepContent = ({ step, isTransition, transitionDuration }) => {

  const pointsRender = step.points.map(point => {
    return (
      <li key={point}>
        <img src={check} alt={point} />
        {point}
      </li>
    )
  })

  return (
    <StyledContainer>
      <StyleContent
        isTransition={isTransition}
        transitionDuration={transitionDuration}
      >
        <img src={step.icon} alt={step.title} />
        <h3>{step.title}</h3>
        <ul>
          {pointsRender}
        </ul>
      </StyleContent>
    </StyledContainer>
  )
}

export default StepContent
