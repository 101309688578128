import React from 'react'

const humainIcon = () => {
  return (
    <svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.7058 30L60.7059 27L59.2919 28.414L60.5849 29.707H57.9789C57.7189 22.896 54.808 16.554 49.9991 11.941V4.707H54.585L53.292 6L54.706 7.414L57.7059 4.414C58.0969 4.023 58.0969 3.391 57.7059 3L54.706 0L53.292 1.414L54.585 2.707H48.9991C48.4471 2.707 47.9991 3.155 47.9991 3.707V10.209C46.8652 9.324 45.6502 8.526 44.3582 7.827L43.4062 9.586C51.1741 13.79 55.999 21.883 55.999 30.707C55.999 39.53 51.1741 47.623 43.4072 51.828L44.3592 53.587C45.6502 52.888 46.8652 52.09 47.9991 51.205V57.707C47.9991 58.259 48.4471 58.707 48.9991 58.707H54.585L53.292 60L54.706 61.414L57.7059 58.414C58.0969 58.023 58.0969 57.391 57.7059 57L54.706 54L53.292 55.414L54.585 56.707H49.9991V49.473C54.808 44.86 57.7189 38.518 57.9789 31.707H60.5849L59.2919 33L60.7059 34.414L63.7058 31.414C64.0968 31.023 64.0968 30.391 63.7058 30Z" fill="#C91200" />
      <path d="M8.00007 30.707C8.00007 21.883 12.825 13.79 20.5928 9.586L19.6408 7.827C18.3498 8.526 17.1339 9.324 15.9999 10.209V3.707C15.9999 3.155 15.5519 2.707 14.9999 2.707H9.41404L10.707 1.414L9.29304 0L6.29311 3C5.90212 3.391 5.90212 4.023 6.29311 4.414L9.29304 7.414L10.707 6L9.41404 4.707H13.9999V11.941C9.19104 16.554 6.28011 22.896 6.02011 29.707H3.41417L4.70714 28.414L3.29318 27L0.293243 30C-0.0977478 30.391 -0.0977478 31.023 0.293243 31.414L3.29318 34.414L4.70714 33L3.41417 31.707H6.02011C6.28011 38.518 9.19104 44.86 13.9999 49.473V56.707H9.41404L10.707 55.414L9.29304 54L6.29311 57C5.90212 57.391 5.90212 58.023 6.29311 58.414L9.29304 61.414L10.707 60L9.41404 58.707H14.9999C15.5519 58.707 15.9999 58.259 15.9999 57.707V51.205C17.1329 52.09 18.3488 52.888 19.6398 53.587L20.5918 51.828C12.825 47.623 8.00007 39.53 8.00007 30.707Z" fill="#C91200" />
      <path d="M39.2995 28.426L44.2024 30.574C45.2943 31.052 45.9993 32.13 45.9993 33.322V46.707H47.9993V33.322C47.9993 31.337 46.8243 29.539 45.0053 28.742L38.7055 25.982L36.4965 23.037C37.9915 22.172 38.9995 20.555 38.9995 18.707V15.707H36.9995V18.707C36.9995 20.361 35.6536 21.707 33.9996 21.707H29.9997C28.3457 21.707 26.9998 20.361 26.9998 18.707V9.70703C26.9998 8.05303 28.3457 6.70703 29.9997 6.70703H33.9996C35.6536 6.70703 36.9995 8.05303 36.9995 9.70703V11.707H32.9996C31.8966 11.707 30.9997 10.81 30.9997 9.70703H28.9997C28.9997 11.913 30.7937 13.707 32.9996 13.707H37.9995C38.5515 13.707 38.9995 13.259 38.9995 12.707V9.70703C38.9995 6.95003 36.7565 4.70703 33.9996 4.70703H29.9997C27.2427 4.70703 24.9998 6.95003 24.9998 9.70703V18.707C24.9998 20.555 26.0078 22.172 27.5027 23.037L25.3068 25.965L19.1399 28.437C17.232 29.201 16 31.023 16 33.078V46.707H18V33.078C18 31.845 18.7389 30.752 19.8839 30.293L24.7108 28.358L24.0138 32.542C23.9568 32.88 24.0778 33.224 24.3338 33.453C24.5198 33.619 24.7568 33.707 24.9998 33.707C25.0918 33.707 25.1838 33.694 25.2748 33.668L29.8187 32.37L28.0047 49.602C27.9727 49.907 28.0827 50.209 28.3017 50.423L31.3847 53.423C31.5726 53.605 31.8226 53.707 32.0826 53.707H32.0966C32.3626 53.703 32.6156 53.594 32.7996 53.404L35.7166 50.404C35.9235 50.191 36.0255 49.897 35.9945 49.602L34.1806 32.37L38.7245 33.668C38.8155 33.694 38.9075 33.707 38.9995 33.707C39.2425 33.707 39.4795 33.619 39.6655 33.453C39.9215 33.224 40.0425 32.881 39.9855 32.542L39.2995 28.426ZM26.2468 31.311L26.7238 28.449L29.5857 30.357L26.2468 31.311ZM32.0636 51.292L30.0447 49.329L31.8926 31.777L31.9456 31.762C31.9816 31.764 32.0176 31.764 32.0536 31.762L32.1066 31.777L33.9556 49.345L32.0636 51.292ZM31.9996 29.505L27.4337 26.461L29.4997 23.707H34.4996L36.5655 26.461L31.9996 29.505ZM34.4146 30.357L37.2765 28.449L37.7535 31.311L34.4146 30.357Z" fill="#C91200" />
      <path d="M24 36.707H22V50.707H24V36.707Z" fill="#C91200" />
      <path d="M42 46.707H40V50.707H42V46.707Z" fill="#C91200" />
      <path d="M42 36.707H40V44.707H42V36.707Z" fill="#C91200" />
    </svg>
  )
}

export default humainIcon


