import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import SecondaryBtn from 'components/global/Button/SecondaryBtn';

const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin: 0 7.5px;
  border-radius: ${props => props.theme.baseBorderRadius};
  padding: 40px;
  cursor: pointer;
  ${breakpoint('sm')`
    margin: 0 15px;
  `}
  p {
    margin: 30px 0;
  }
  a {
    width: fit-content;
  }
`

const PartnerCard = ({ partner }) => {
  const logoImage = getImage(partner.logo)
  return (
    <StyledContainer>
      <GatsbyImage image={logoImage} alt={partner.siteUrl} />
      <p>{partner.description.description}</p>
      <a href={partner.siteUrl} target="_blank" rel="noreferrer">
        <SecondaryBtn>
          Voir le site
        </SecondaryBtn>
      </a>
    </StyledContainer>
  )
}

export default PartnerCard
