import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import Heading from 'components/global/Heading'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  ${breakpoint('md')`
    margin-bottom: 0;
  `}
`
const StyledHeading = styled(Heading)`
  margin: 0 0 30px;
`
const StyledProgressBar = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.brandLight};;
  position: relative;
  height: 2px;
  &:after {
    content: '';
    transition: width .2s linear;
    position: absolute;
    height: 100%;
    background-color: ${props => props.theme.colors.brand};
    width: ${props => props.barProgress}%;
  }
`
const StyledBarContainer = styled.div`
  margin-top: 25px;
  color: ${props => props.theme.colors.brand};
  p {
    margin-bottom: 5px;
  }
  * {
    font-weight: 700;
  }
`
const StyledStepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledStepNumber = styled.div`
  cursor: pointer;
  position: relative;
  top: -6px;
  transition: color ${props => props.transitionDuration}ms ${props => props.theme.baseCubicBezier};
  color: ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLight};
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${props => props.isActive ? props.theme.colors.brand : props.theme.colors.brandLight};
  }
`


const ProgressBar = ({ barProgress, transitionDuration, projectSteps, activeStepIndex, callBackActiveStep }) => {

  const onStepNumberMouseEnter = (step) => {
    callBackActiveStep(step)
  }

  const stepsRender = projectSteps.map((step, index) => {
    return (
      <StyledStepNumber
        key={step.title}
        isActive={index <= activeStepIndex}
        transitionDuration={transitionDuration}
        onMouseEnter={() => onStepNumberMouseEnter(index)}
      >
        {index + 1}
      </StyledStepNumber>
    )
  })

  return (
    <StyledContainer>
      <StyledHeading section alignLeft>De l’AMO à la maîtrise des Usages</StyledHeading>
      <p>Bureau d’études techniques et conseil en matières d’infrastructures de communication, sécurité et sûreté, nos expertises permettent de vous accompagner, dans tous les secteurs d’activité, tout au long des phases qui constituent votre projet.</p>
      <StyledBarContainer>
        <p>Etapes</p>
        <StyledProgressBar
          barProgress={barProgress}
          transitionDuration={transitionDuration}
        />
        <StyledStepsContainer >
          {stepsRender}
        </StyledStepsContainer>
      </StyledBarContainer>
    </StyledContainer>
  )
}

export default ProgressBar
