import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import ProgressBar from './ProgressBar'
import StepContent from './StepContent'

const StyledContainer = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${breakpoint('md')`
    flex-direction: row;
    align-items: center;
  `}

  & > *  {
    flex: 0 0 45%;
    &:first-child {
      display: flex;
      flex-direction: column;
    }
  }
`

const ProjectStepsContainer = ({ projectSteps, isSectionVisible }) => {

  const transitionDuration = 400
  const projectStepsLength = projectSteps.length
  const fullBarProgress = 100
  const stepGap = fullBarProgress / (projectStepsLength - 1)

  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [barProgress, setBarProgress] = useState(0)

  const [isStepNumberHovered, setIsStepNumberHovered] = useState(false)
  const [isTransition, setIsTransition] = useState(false)

  const handleMouseEnterStep = (step) => {
    setIsStepNumberHovered(true)
    handleMouseStepTransition(step)
    setBarProgress((step) * stepGap)
  }

  const handleMouseStepTransition = (step) => {
    setIsTransition(true)
    setActiveStepIndex(step)
    setTimeout(() => {
      setIsTransition(false)
    }, transitionDuration / 2)
  }

  const handleAutoStepTransition = (step) => {
    setIsTransition(true)
    setTimeout(() => {
      setIsTransition(false)
      setActiveStepIndex(step)
    }, transitionDuration / 2)
  }

  useEffect(() => {
    if (isSectionVisible && !isStepNumberHovered) {
      let i = 0;
      let interval = setInterval(() => {
        setBarProgress(i++)
        for (let step = activeStepIndex + 1; step < projectStepsLength; step++) {
          if (i === step * stepGap) handleAutoStepTransition(step)
        }
        if (i >= fullBarProgress) clearInterval(interval)
      }, 200);
      return () => clearInterval(interval);
    } else if (!isSectionVisible) {
      setActiveStepIndex(0)
      setBarProgress(0)
      setIsStepNumberHovered(false)
    }
  }, [isStepNumberHovered, isSectionVisible])

  const activeStep = projectSteps.find((step, index) => index === activeStepIndex)

  return (
    <StyledContainer>
      <ProgressBar
        barProgress={barProgress}
        transitionDuration={transitionDuration}
        projectSteps={projectSteps}
        activeStepIndex={activeStepIndex}
        callBackActiveStep={handleMouseEnterStep}
      />
      <StepContent
        step={activeStep}
        isTransition={isTransition}
        transitionDuration={transitionDuration}
      />
    </StyledContainer>
  )
}

export default ProjectStepsContainer
