import React from 'react'
import styled from 'styled-components'
import Heading from 'components/global/Heading'
import { useStaticQuery, graphql } from 'gatsby'
import PartnersSlider from './PartnersSlider'

const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.grey};
  padding-bottom: 120px;
  overflow: auto;
`
const OurPartnersSection = () => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulPartners(        
        sort: { fields: [ordre], order: ASC },
      ) {
        nodes {
          id
          siteUrl
          description {
            description
          }
          logo {
            gatsbyImageData(
              layout: CONSTRAINED,
              placeholder: TRACED_SVG,
              height: 60,
            )
          }
        }
      }
    }
  `)

  const partnersData = data.allContentfulPartners.nodes

  return (
    <StyledContainer>
      <Heading section>Nos partenaires et certifications</Heading>
      <PartnersSlider partnersData={partnersData} />
    </StyledContainer>
  )
}

export default OurPartnersSection
