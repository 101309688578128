import React from 'react'

const relationIcon = () => {
  return (
    <svg width="65" height="60" viewBox="0 0 65 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M62.0098 30.985H60.0098V36.985H62.0098V30.985Z" fill="#C91200" />
      <path d="M64 44.985L56.009 45.059V28.985H64.009V26.985H55.009C54.456 26.985 54.009 27.432 54.009 27.985V28.728L42.234 26.011C42.068 25.974 41.897 25.978 41.735 26.024L28.031 29.939L19.326 27.037C19.161 26.981 18.985 26.97 18.813 27.005L10.009 28.765V27.985C10.009 27.432 9.562 26.985 9.009 26.985H0.009V28.985H8.009V44.985V45.059L0.018 44.985L0 46.985L9 47.068C9.003 47.068 9.006 47.068 9.009 47.068C9.273 47.068 9.526 46.964 9.713 46.778C9.87 46.622 9.97 46.42 9.999 46.203L13.439 46.891L16.977 51.584C17.039 51.666 17.113 51.737 17.196 51.797L28.428 59.799C28.6 59.922 28.803 59.985 29.009 59.985C29.074 59.985 29.14 59.978 29.205 59.965L34.19 58.968L40.172 57.971C40.173 57.971 40.175 57.97 40.176 57.97C40.218 57.963 40.261 57.953 40.302 57.94C40.306 57.939 40.309 57.938 40.313 57.937C40.332 57.931 40.351 57.924 40.37 57.917C40.371 57.917 40.372 57.916 40.373 57.916C40.375 57.915 40.377 57.915 40.379 57.914L45.379 55.914C45.685 55.792 45.91 55.527 45.983 55.206C46.055 54.885 45.965 54.549 45.742 54.307L44.75 53.233L51.455 49.881C51.584 49.817 51.698 49.724 51.789 49.611L54.177 46.626C54.214 46.681 54.256 46.732 54.304 46.779C54.492 46.965 54.744 47.069 55.008 47.069C55.011 47.069 55.014 47.069 55.017 47.069L64.017 46.986L64 44.985ZM50.365 48.189L43.339 51.702L33.743 41.306L32.274 42.663L43.289 54.596L40.25 55.811L28.716 44.278L27.302 45.692L37.928 56.318L34.32 56.92L24.678 48.242L23.34 49.728L31.859 57.395L29.237 57.919L18.483 50.258L14.808 45.383C14.661 45.187 14.446 45.052 14.205 45.004L10.009 44.166V30.804L18.945 29.017L26.856 31.654L26.029 35.789C25.976 36.055 26.033 36.33 26.186 36.554C26.34 36.777 26.578 36.927 26.845 36.972L32.845 37.972C33.055 38.006 33.267 37.974 33.456 37.88L36.811 36.202L41.301 40.692C41.489 40.88 41.743 40.985 42.009 40.985H47.009V38.985H42.423L37.716 34.278C37.411 33.973 36.947 33.898 36.562 34.09L32.853 35.944L28.193 35.168L28.871 31.778L42.036 28.016L54.009 30.781V43.635L50.365 48.189Z" fill="#C91200" />
      <path d="M4.00977 30.985H2.00977V36.985H4.00977V30.985Z" fill="#C91200" />
      <path d="M24.0098 12.985H26.0098V17.985H28.0098V11.985C28.0098 11.432 27.5628 10.985 27.0098 10.985H25.0098V10.365L32.0098 2.49L39.0098 10.365V10.985H37.0098C36.4568 10.985 36.0098 11.432 36.0098 11.985V17.985H38.0098V12.985H40.0098C40.5628 12.985 41.0098 12.538 41.0098 11.985V9.985C41.0098 9.74 40.9198 9.504 40.7568 9.321L32.7568 0.321C32.3778 -0.107 31.6418 -0.107 31.2628 0.321L23.2628 9.321C23.0998 9.504 23.0098 9.74 23.0098 9.985V11.985C23.0098 12.538 23.4568 12.985 24.0098 12.985Z" fill="#C91200" />
      <path d="M28.0098 19.985H26.0098V23.985H28.0098V19.985Z" fill="#C91200" />
      <path d="M38.0098 19.985H36.0098V23.985H38.0098V19.985Z" fill="#C91200" />
      <path d="M33.0098 13.985H31.0098V19.985H33.0098V13.985Z" fill="#C91200" />
      <path d="M33.0098 21.985H31.0098V23.985H33.0098V21.985Z" fill="#C91200" />
      <path d="M49.0098 13.985H50.0098V17.985H52.0098V12.985C52.0098 12.432 51.5628 11.985 51.0098 11.985H50.0098V11.823L55.0098 6.45304L60.0098 11.823V11.985H59.0098C58.4568 11.985 58.0098 12.432 58.0098 12.985V17.985H60.0098V13.985H61.0098C61.5628 13.985 62.0098 13.538 62.0098 12.985V11.429C62.0098 11.176 61.9138 10.933 61.7408 10.747L55.7408 4.30304C55.3638 3.89704 54.6548 3.89704 54.2778 4.30304L48.2778 10.747C48.1058 10.933 48.0098 11.176 48.0098 11.429V12.985C48.0098 13.538 48.4568 13.985 49.0098 13.985Z" fill="#C91200" />
      <path d="M52.0098 19.985H50.0098V21.985H52.0098V19.985Z" fill="#C91200" />
      <path d="M60.0098 19.985H58.0098V21.985H60.0098V19.985Z" fill="#C91200" />
      <path d="M56.0098 16.985H54.0098V21.985H56.0098V16.985Z" fill="#C91200" />
      <path d="M3.00977 13.985H4.00977V17.985H6.00977V12.985C6.00977 12.432 5.56277 11.985 5.00977 11.985H4.00977V11.823L9.00977 6.45304L14.0098 11.823V11.985H13.0098C12.4568 11.985 12.0098 12.432 12.0098 12.985V17.985H14.0098V13.985H15.0098C15.5628 13.985 16.0098 13.538 16.0098 12.985V11.429C16.0098 11.176 15.9138 10.933 15.7408 10.747L9.74077 4.30304C9.36377 3.89704 8.65477 3.89704 8.27777 4.30304L2.27777 10.747C2.10577 10.933 2.00977 11.176 2.00977 11.429V12.985C2.00977 13.538 2.45677 13.985 3.00977 13.985Z" fill="#C91200" />
      <path d="M6.00977 19.985H4.00977V21.985H6.00977V19.985Z" fill="#C91200" />
      <path d="M14.0098 19.985H12.0098V21.985H14.0098V19.985Z" fill="#C91200" />
      <path d="M10.0098 16.985H8.00977V21.985H10.0098V16.985Z" fill="#C91200" />
    </svg>

  )
}

export default relationIcon



